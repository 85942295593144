
import { defineComponent, reactive, watch } from "vue";
import BaseCard from "@/app/core/components/cards/BaseCard.vue";
import BaseInput from "@/app/core/components/forms/BaseInput.vue";
import BaseCheckbox from "@/app/core/components/forms/BaseCheckbox.vue";
import { useProfileModule } from "@/store/modules/ProfileModule";
import { EquipmentUpdateRequestContract } from "@/app/core/interfaces/services/equipment/EquipmentUpdateRequestContract";
import { container } from "tsyringe";
import { EquipmentService } from "@/app/core/services/EquipmentService";
import { Equipment } from "@/app/core/models/domain/Equipment";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "EquipmentRouteInfo",
  components: { BaseCheckbox, BaseInput, BaseCard },
  async setup(props, context) {
    const router = useRouter();
    const toast = useToast();
    const profileCtx = useProfileModule();
    const equipmentService: EquipmentService = container.resolve(EquipmentService);

    const checked = reactive({
      opened: !!profileCtx.getters.getEquipment?.numberOfTrailersOpened,
      enclosed: !!profileCtx.getters.getEquipment?.numberOfTrailersEnclosed,
    });

    const equipmentUpdateForm = reactive<EquipmentUpdateRequestContract>({
      description: profileCtx.getters.getEquipment?.description,
      numberOfTrucks: profileCtx.getters.getEquipment?.numberOfTrucks,
      numberOfTrailersOpened: profileCtx.getters.getEquipment?.numberOfTrailersOpened,
      numberOfTrailersEnclosed: profileCtx.getters.getEquipment?.numberOfTrailersEnclosed,
    });

    watch(
      () => [
        checked.opened,
        checked.enclosed,
        equipmentUpdateForm.numberOfTrucks,
        equipmentUpdateForm.description,
      ],
      () => {
        if (!checked.opened) {
          equipmentUpdateForm.numberOfTrailersOpened = null;
        }
        if (!checked.enclosed) {
          equipmentUpdateForm.numberOfTrailersEnclosed = null;
        }
        if (!equipmentUpdateForm.numberOfTrucks) {
          equipmentUpdateForm.numberOfTrucks = null;
        }
        if (!equipmentUpdateForm.description) {
          equipmentUpdateForm.description = null;
        }
      }
    );

    if (!profileCtx.state.billing) {
      await profileCtx.actions.fetchEquipment();
    }

    const updateEquipment = () => {
      equipmentService
        .update<EquipmentUpdateRequestContract, Equipment>(equipmentUpdateForm)
        .then((res) => {
          profileCtx.mutations.setEquipment(res);
          toast.success("Successfully updated equipment");
          router.push({ name: "Profile" });
        });
    };

    return {
      checked,
      equipmentUpdateForm,
      updateEquipment,
    };
  },
});
